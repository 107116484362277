<template>
   <div class="ServicesAddDialog">
      <Dialog v-if="showAddDialog"
         top="100px"
         class="blue"
         :header="addDialogHeader"
         @close="showAddDialog = false"
         @outsideClick="showAddDialog = false">
         <ProjectAdd v-if="showAddDialog == 'project'"
            :config="config"
            :customer_id="customer_id"
            :customerName="customerName"
            @addProject="onAddProject" />
         <TaskAdd v-if="showAddDialog == 'task'"
            :config="config"
            :customer_id="customer_id"
            :customerName="customerName"
            :project_id="project_id"
            :projectName="projectName"
            @addService="onAddTask" />
         <FixedPriceTaskAdd v-if="showAddDialog == 'fixedPriceTask'"
            :config="config"
            :customer_id="customer_id"
            :customerName="customerName"
            :project_id="project_id"
            :projectName="projectName"
            @addService="onAddFixedPriceTask" />
         <ServiceAdd v-if="showAddDialog == 'service'"
            :config="config"
            :customer_id="customer_id"
            :customerName="customerName"
            :project_id="project_id"
            :projectName="projectName"
            @addService="onAddService" />
         <BchProductAddVue v-if="showAddDialog == 'bchProduct'"
            :config="config"
            :customer_id="customer_id"
            :customerName="customerName"
            :project_id="project_id"
            :projectName="projectName"
            @addService="onAddBchProduct" />
      </Dialog>
   </div>
</template>

<script>
import ProjectAdd from './project/ProjectAdd.vue';
import TaskAdd from './task/TaskAdd.vue';
import FixedPriceTaskAdd from './fixedPriceTask/FixedPriceTaskAdd.vue';
import ServiceAdd from './service/ServiceAdd.vue';
import BchProductAddVue from './bchProduct/BchProductAdd.vue';

import Dialog from '@components/Dialog/Dialog.vue';
import { toDbServiceObject, } from './components/utils'
import { getProjectDataObject } from "./project/project"
import { getServiceDataObject } from "./service/service"
import { getTaskDataObject } from './task/task';
import { getFixedPriceTaskDataObject } from './fixedPriceTask/fixedPriceTask';
import { getNewBchProductDataObject } from './bchProduct/bchProduct';
import { ObjectId, shortId } from '@SyoLab/utils'
import api from '@components/Portal/api'
import { Timestamp } from "@SyoLab/date-time"
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()


export default {
   name: 'ServicesAddDialog',
   components: { ProjectAdd, TaskAdd, Dialog, FixedPriceTaskAdd, ServiceAdd, BchProductAddVue },
   props: {
      config: { required: true },
      services: { required: true },
   },
   data() {
      return {
         showAddDialog: false,
         customer_id: null,
         customerName: null,
         notBillable: null,
         project_id: null,
         projectName: null,
         serviceType: null,
         accountNo: null,
         defaultWorkType: null,
         businessDomain: null,

      }
   },
   methods: {
      addService(data) {

         console.log('addService', data)

         this.customer_id = data.customer_id;
         this.customerName = data.customerName;
         this.notBillable = data.notBillable;
         this.project_id = data.project_id;
         this.projectName = data.projectName;
         this.serviceType = data.serviceType;
         this.accountNo = data.accountNo;
         this.businessDomain = data.businessDomain;
         this.defaultWorkType = data.defaultWorkType;
         this.bchProductId = data.bchProductId;

         if (data.customer_id && data.project_id) {
            if (data.type == 'task') {
               this.onAddTask(data)
               return
            }
            if (data.type == 'project') {
               this.onAddProject(data)
               return
            }
            if (data.type == 'fixedPriceTask') {
               this.onAddFixedPriceTask(data)
               return
            }
            if (data.type == 'service') {
               this.onAddService(data)
               return
            }
            if (data.type == 'bchProduct') {
               this.onAddBchProduct(data)
               return
            }
         } else {
            this.showAddDialog = data.type;
         }
      },
      onAddProject(data) {

         this.showAddDialog = false
         let project = getProjectDataObject({
            _id: ObjectId(),
            project_id: shortId(),
            createdBy: portalState.user.userId,
            createdTimeStamp: Timestamp(),
            customer_id: this.customer_id,
            customerName: this.customerName,
            projectName: this.projectName,
            serviceType: this.serviceType,
            color: this.getServiceColor(this.serviceType),
            accountNo: this.accountNo,
            businessDomain: this.businessDomain,
            defaultWorkType: this.defaultWorkType,
            _services: [], // all services of project
            ...data
         },)

         if (this.notBillable) project.notBillable = true
         this.updateTimeStamp(project)
         this.services.push(project)
         api.put(`${this.config.apiUrl}/services`, toDbServiceObject(project))
      },

      // task
      onAddTask(data) {

         if (!data) data = {}
         let name = 'Neuer Task'
         if (data.project_id) {
            // set default task name from project name
            let tasks = this.services.filter(s => s.project_id == data.project_id && s.type == 'task')
            name += `(${tasks.length + 1})`
         }

         let task = getTaskDataObject({
            _id: ObjectId(),
            _isNew: true,
            createdBy: portalState.user.userId,
            createdTimeStamp: Timestamp(),
            name,
            status: 'new',
            customer_id: this.customer_id,
            customerName: this.customerName,
            project_id: this.project_id,
            projectName: this.projectName,
            serviceType: this.serviceType,
            color: this.getServiceColor(this.serviceType),
            accountNo: this.accountNo,
            businessDomain: this.businessDomain,
            defaultWorkType: this.defaultWorkType,
            ...data
         }, {
            // default settings
            showDate: false,
            showText: false,
            showFromTo: false,
            showDuration: true,
            showEmployee: false,
            showHourlyRate: true,
            showNonBillable: false
         })

         if (this.notBillable) task.notBillable = true
         this.showAddDialog = false
         this.updateTimeStamp(task)
         //task will be initially saved in TaskEditDialog by pressing save 
         setTimeout(() => {
            this.$emit('edit', task)
         }, 100)

      },
      onAddFixedPriceTask(data) {

         if (!data) data = {}

         let name = 'Neuer Festpreis-Task'
         if (data.project_id) {
            // set default task name from project name
            let tasks = this.services.filter(s => s.project_id == data.project_id && s.type == 'task')
            name += `(${tasks.length + 1})`
         }

         let task = getFixedPriceTaskDataObject({
            _id: ObjectId(),
            _isNew: true,
            createdBy: portalState.user.userId,
            createdTimeStamp: Timestamp(),
            name,
            status: 'new',
            customer_id: this.customer_id,
            customerName: this.customerName,
            project_id: this.project_id,
            projectName: this.projectName,
            accountNo: this.accountNo,
            serviceType: this.serviceType,
            color: this.getServiceColor(this.serviceType),
            businessDomain: this.businessDomain,
            defaultWorkType: this.defaultWorkType,

            ...data
         }, {
            // default settings
            showServiceName: true,
         })

         if (this.notBillable) task.notBillable = true
         this.showAddDialog = false
         this.updateTimeStamp(task)
         //task will be initially saved in TaskEditDialog by pressing save 
         setTimeout(() => {
            this.$emit('edit', task)
         }, 100)
      },
      onAddService(data) {

         if (!data) data = {}

         let name = 'Neue Leistung'
         if (data.project_id) {
            // set default task name from project name
            let tasks = this.services.filter(s => s.project_id == data.project_id && s.type == 'task')
            name += `(${tasks.length + 1})`
         }

         let task = getServiceDataObject({
            _id: ObjectId(),
            _isNew: true,
            createdBy: portalState.user.userId,
            createdTimeStamp: Timestamp(),
            name,
            status: 'new',
            customer_id: this.customer_id,
            customerName: this.customerName,
            project_id: this.project_id,
            projectName: this.projectName,
            serviceType: this.serviceType,
            color: this.getServiceColor(this.serviceType),
            accountNo: this.accountNo,
            businessDomain: this.businessDomain,
            ...data
         }, {
            // default settings
            showServiceName: true,
         })

         if (this.notBillable) task.notBillable = true
         this.showAddDialog = false
         this.updateTimeStamp(task)
         //task will be initially saved in TaskEditDialog by pressing save 
         setTimeout(() => {
            this.$emit('edit', task)
         }, 100)
      },
      onAddBchProduct(data) {

         if (!data) data = {}

         let task = getNewBchProductDataObject({
            _id: ObjectId(),
            _isNew: true,
            createdBy: portalState.user.userId,
            createdTimeStamp: Timestamp(),
            status: 'new',
            customer_id: this.customer_id,
            customerName: this.customerName,
            project_id: this.project_id,
            projectName: this.projectName,
            serviceType: this.serviceType,
            color: this.getServiceColor(this.serviceType),
            accountNo: this.accountNo,
            bchProductId: this.bchProductId,
            businessDomain: this.businessDomain,
            ...data
         }, {
            // default settings
            showServiceName: true,
         })

         if (this.notBillable) task.notBillable = true
         this.showAddDialog = false
         this.updateTimeStamp(task)
         //task will be initially saved in TaskEditDialog by pressing save 
         setTimeout(() => {
            this.$emit('edit', task)
         }, 100)
      },
      updateTimeStamp(record) {
         record.timeStamp = Timestamp()
         record.userId = portalState.user.userId
      },
      getServiceColor(serviceType) {
         let addServiceItem = this.config.addServiceList.find(item => item.serviceType == serviceType)
         return addServiceItem?.color || null
      }
   },
   computed: {
      addDialogHeader() {
         return this.showAddDialog == 'project' ? 'Neues Projekt' : 'Neue Leistung'
      },
   },
}
</script>

<style scoped>
.ServicesAddDialog {}
</style>