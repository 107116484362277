<template>
   <div class="ServicesAddDropdown">
      <Dropdown class="rounded addService"
         placeholder="Leistung hinzufügen"
         :options="addServiceListItems"
         v-model="addService"
         @input="onAddService()">
         <template #before>
            <Icon class="iconSearch"
               :icon="$static.iconSearch" />
         </template>
      </Dropdown>
      <div v-if="showAddServiceBtn"
         class="btn add"
         @click="onAddService()">
         +
      </div>
      <Dialog v-if="showAddDialog"
         top="100px"
         class="blue rounded"
         header="Neue Leistung"
         @close="showAddDialog = false"
         @outsideClick="showAddDialog = false">
         <div class="dlgBody"
            @keyup.prevent="onKeyup">
            <div class="grid">
               <div v-for="(service, index) in addServiceDialogItems"
                  ref="serviceItem"
                  :key="index"
                  :tabindex="100 + index"
                  :class="{ active: isActive(service) }"
                  class="addServiceItem"
                  @focus="onFocus(service)"
                  @mouseenter="onMouseenter(service)"
                  @mouseleave="onMouseleave(service)"
                  @keyup.enter="onSelect(service)"
                  @click="onSelect(service)">{{ service.name }}</div>
            </div>
         </div>
      </Dialog>

   </div>
</template>

<script>
import Dropdown from '@components/Form/Dropdown.vue';
import Icon from "@icons/Icon.vue"
import { Add, Search } from "@icons/appFabric/icons"
import Dialog from '@components/Dialog/Dialog.vue';
export default {
   name: 'ServicesAddDropdown',
   components: { Icon, Dropdown, Dialog },
   props: {
      config: { required: true },
   },
   data() {
      return {
         addService: null, // selected key (dropdown)
         showAddDialog: false,
         addServiceSelectedType: null,
         addServiceItemHover: null,
         addServiceCustomerData: {},
      }
   },
   static: {
      iconSearch: Search,
   },
   methods: {
      onAddService() {
         this.addServiceCustomerData = {}
         if (!this.addService) {
            this.addServiceSelectedType = null
            return
         }
         if (!this.addServiceSelectedListItem) return
         if (this.addServiceSelectedListItem.emit) {
            this.onEmit(this.addServiceSelectedListItem.emit)
            setTimeout(() => {
               this.addService = null
            }, 100);
            return
         }
         if (!this.addServiceSelectedListItem.services || this.addServiceSelectedListItem.services.length == 0) {
            this.addServiceSelectedType = null
            return
         }
         this.addServiceSelectedType = this.idFromItem(this.addServiceSelectedListItem.services[0])
         this.showAddDialog = true
         setTimeout(() => {
            this.$refs.serviceItem[0].focus()
         }, 200);
      },
      addServiceType(data) {

         console.log('addServiceType', data)
         this.addServiceCustomerData = { ...data }
         delete this.addServiceCustomerData.serviceType

         let listItem = this.addServiceList.find(item => item.serviceType == data.serviceType)
         this.addService = listItem?.key
         this.addServiceSelectedType = this.idFromItem(this.addServiceSelectedListItem.services[0])
         this.showAddDialog = true
         setTimeout(() => {
            this.$refs.serviceItem[0].focus()
         }, 200);
      },
      onMouseenter(item) {
         this.addServiceItemHover = this.idFromItem(item)
      },
      onMouseleave(item) {
         let id = this.idFromItem(item)
         setTimeout(() => {
            if (this.addServiceItemHover == id) this.addServiceItemHover = null
         }, 100);

      },
      isActive(item) {
         let id = this.idFromItem(item)
         console.log('isActive', id, this.addServiceSelectedType, this.addServiceItemHover)
         if (this.addServiceItemHover) return this.addServiceItemHover == id
         return this.addServiceSelectedType == id
      },
      onKeyup($event) {

         if ($event.key != 'ArrowDown' && $event.key != 'ArrowUp') return

         let index = this.addServiceDialogItems.findIndex(item => this.idFromItem(item) == this.addServiceSelectedType)
         if ($event.key == 'ArrowDown') {
            index = index == this.addServiceDialogItems.length - 1 ? 0 : index + 1
         }
         if ($event.key == 'ArrowUp') {
            index = index == 0 ? this.addServiceDialogItems.length - 1 : index - 1
         }
         this.addServiceSelectedType = this.idFromItem(this.addServiceDialogItems[index])
         this.$refs.serviceItem[index].focus()
      },
      onSelect(service) {
         this.$emit('addService', {
            defaultWorkType: this.addServiceSelectedListItem.defaultWorkType || null,
            businessDomain: this.addServiceSelectedListItem.businessDomain,
            accountNo: this.addServiceSelectedListItem.accountNo,
            serviceType: this.addServiceSelectedListItem.serviceType,
            // service
            type: service.type,
            bchProductId: service.bchProductId,
            // customer / project
            ...this.addServiceCustomerData,
         })
         this.showAddDialog = false
         this.addServiceCustomerData = {}
      },
      onEmit(eventName) {
         this.$emit('emit', eventName, {
            event: eventName,
            defaultWorkType: this.addServiceSelectedListItem.defaultWorkType || null,
            businessDomain: this.addServiceSelectedListItem.businessDomain,
            accountNo: this.addServiceSelectedListItem.accountNo,
            serviceType: this.addServiceSelectedListItem.serviceType
         })
      },
      onFocus(item) {
         this.addServiceSelectedType = this.idFromItem(item)
      },
      idFromItem(item) {
         let id = item.type
         if (item.bchProductId) id += item.bchProductId
         if (item.type == 'project') id = 'project'
         return id
      },
   },
   computed: {
      addServiceList() {
         return this.config.addServiceList
      },
      addServiceListItems() {
         return {
            listItems: this.addServiceList,
            autocomplete: true,
            autocompleteKeys: true,
            autocompleteClearable: true
         }
      },
      addServiceSelectedListItem() {
         return this.addServiceList.find(item => item.key == this.addService)
      },
      addServiceDialogItems() {
         return this.addServiceSelectedListItem?.services || []
      },
      showAddServiceBtn() {
         if (!this.addService) return false
         return this.addServiceSelectedListItem?.services && this.addServiceSelectedListItem.services.length > 0
      }
   }

}
</script>

<style scoped>
.ServicesAddDropdown {
   display: flex;
   align-items: center;
   column-gap: 15px;
}

.Icon.iconSearch {
   font-size: 14px;
   margin-top: 3px;
}

.Dropdown.addService {
   width: 400px;
   background-color: var(--activeHover);
}


.btn.add {
   color: black;
   border: 1px solid rgb(217, 217, 217);
   border-radius: 50%;
   height: 36px;
   width: 36px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: rgb(51 51 51);
   font-weight: 300;
   font-size: 20px;
}

.dlgBody {
   display: flex;
   padding-top: 20px;
}

.dlgBody .grid {
   display: grid;
   grid-template-columns: [name] max-content;
   gap: 3px;
}

.addServiceItem {
   font-weight: 500;
   font-size: 14px;
   text-transform: uppercase;
   color: var(--accentBlue);
   display: flex;
   align-items: center;
   padding: 6px 11px;
   cursor: pointer;
   border-radius: 3px;
   outline: none;
}

.addServiceItem.active {
   background-color: var(--activeHover);
}
</style>