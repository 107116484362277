<template>
   <div class="BillingPreview">
      <portal :to="fullScreen ? 'fullScreen' : null">
         <div class="display">
            <div class="action">
               <DatePicker class="slim noNotification"
                  :value="billDate"
                  :options="{ format: 'toIsoDate' }"
                  @input="onDateChange" />
               <Dropdown class="billingContact noLabel noNotification"
                  :value="contactSelected"
                  :options="billingContactDropdownOptions"
                  @input="onContactSelect" />
               <Button class="blue"
                  :enabled="enableGenerateBillBtn"
                  @click="onGenerateBill">Rechnung erstellen</Button>
            </div>
            <div v-if="errorMsg"
               class="error">{{ errorMsg }}</div>
            <BillView :bill="bill" />
            <div v-if="loader"
               class="loader">
               <Loader />
            </div>
         </div>
      </portal>
   </div>
</template>

<script>
import BillView from '../../components/services/billing/BillView.vue'
import Button from '@components/Form/Button.vue'
import DatePicker from '@components/Form/DatePicker.vue'
import Dropdown from '@components/Form/Dropdown.vue'
import api from '@components/Portal/api.js'
import { Day, Timestamp } from "@SyoLab/date-time"
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()
import Loader from '@components/Tools/Loader.vue'
import { serviceSort } from '../../components/services/components/utils'

export default {
   name: 'BillingPreView',
   components: {
      BillView,
      Button,
      DatePicker,
      Dropdown,
      Loader
   },
   props: {
      fullScreen: { required: false }
   },
   data() {
      return {
         selectedServices: [],
         contactSelected: null,
         customer: null,
         billDate: new Day().date,
         loader: false,
         errorMsg: null,
         bill: {
            customer_id: null,
            customerId: null,
            customerName: null,
            billNo: null,
            date: null,
            dueDate: null,
            address: {
               firstName: null,
               name: null,
               name2: null,
               street: null,
               zip: null,
               city: null,
               email: null
            },
            services: [],
            servicesValue: 0,
            revenueAccounts: [],
            tax: [],
            value: 0
         }

      }
   },
   methods: {
      onWindowMessage(event) {
         if (event.origin !== window.location.origin) return
         if (event.data?.type == 'billingPreview') {
            this.selectedServices = event.data.selectedServices
            this.update()
         }
      },
      async update() {
         this.loader = true
         this.errorMsg = null
         this.bill.date = this.billDate
         await this.getServicesSelected()
         await this.updateBillNo()
         await this.updateCustomer()
         this.updateBillValues()
         this.loader = false
      },
      async getServicesSelected() {
         this.bill.services = await api.post('billing/servicesSelected', this.selectedServices)
         this.bill.services = serviceSort(this.bill.services)
      },
      async updateCustomer() {
         let customer_id = this.bill.services[0].customer_id
         if (this.customer?._id != customer_id) {
            this.contactSelected = null
         }
         this.customer = await api.get(`billing/customer/${customer_id}`)

         if (this.customer?._id) {
            this.bill.customer_id = this.customer._id
            this.bill.customerId = this.customer.id
            this.bill.customerFirstName = this.customer.firstName
            this.bill.customerName = this.customer.name
            this.bill.customerName2 = this.customer.name2
            this.bill.address.street = this.customer.street
            this.bill.address.street2 = this.customer.street2
            this.bill.address.zip = this.customer.zip
            this.bill.address.city = this.customer.city
            this.bill.address.email = this.contactSelected ? this.contactSelected.email : null

            if (!this.contactSelected) {
               // set first billing contact
               let contact = this.customer.contacts.find(contact => contact.billing && contact.email)
               this.onContactSelect(contact)
            }
         } else {
            this.bill.customer_id = null
            this.bill.customerId = null
            this.bill.customerFirstName = null
            this.bill.customerName = null
            this.bill.customerName2 = null
            this.bill.address.street = null
            this.bill.address.street2 = null
            this.bill.address.zip = null
            this.bill.address.city = null
            this.bill.address.email = null
         }

      },
      updateBillValues() {
         this.bill.date = this.billDate
         this.bill.dueDate = new Day(this.billDate).plus({ month: 1 }).date
         let servicesValue = this.servicesValue()
         this.bill.servicesValue = Number(servicesValue.toFixed(2))
         if (servicesValue > 0) {
            this.bill.tax = this.generateTax()
            this.bill.value = this.billTotal()
            this.bill.revenueAccounts = this.getRevenueAccounts()
         } else {
            this.bill.servicesValue = 0
            this.bill.tax = []
            this.bill.value = 0
            this.bill.revenueAccounts = []
         }

         if (servicesValue == 0) {
            this.errorMsg = 'Rechnungsbetrag ist 0'
            return
         }
         if (this.bill.revenueAccounts.length == 0) {
            this.errorMsg = 'Keine Erlös-Konten vorhanden'
            return
         }
         let total = this.bill.revenueAccounts.reduce((acc, account) => acc + account.value, 0)
         if (total != servicesValue) {
            this.errorMsg = `Erlös-Konten stimmen nicht mit Rechnungsbetrag überein`
            return
         }
         if (!this.bill.customerId) {
            this.errorMsg = 'Keine Kundennummer'
            return
         }
      },
      async updateBillNo(increment = false) {
         if (increment) {
            let { billNo } = await api.post(`billing/billNo`, { increment: true })
            this.bill.billNo = billNo
         } else {
            let { billNo } = await api.get(`billing/billNo`)
            this.bill.billNo = billNo
         }

      },
      generateTax() {
         // TODO: get tax from customer settings and tax settings
         let tax81 = this.bill.servicesValue * 0.081
         tax81 = Number(tax81.toFixed(2))
         let tax = [{ value: tax81, text: 'MwSt 8.1%', taxValue: 8.1 }]

         return tax
      },
      billTotal() {
         let servicesValue = this.servicesValue()
         let taxTotal = this.bill.tax.reduce((acc, tax) => acc + tax.value, 0)
         return Number((servicesValue + taxTotal).toFixed(2))
      },
      servicesValue() {
         return this.bill.services.reduce((acc, service) => {
            let value = service.value || 0
            return acc + value
         }, 0)
      },
      async onGenerateBill() {
         //window.opener.postMessage({ type: 'billingPreview', data: 'generate' }, window.location.origin)
         // set all services except project to billed
         this.loader = true
         let billingTimestamp = Timestamp()

         let billRecord = {
            ...this.bill,
            billedBy: portalState.user.userName,
            billingTimestamp: billingTimestamp,
            payed: false,
         }

         let res = await api.put(`billing/bill`, billRecord)

         // signal parent window to update
         if (window.opener) {
            window.opener.postMessage({ type: 'serviceUpdates', data: this.bill.services }, window.location.origin)
         }
         // open pdf
         if (res.fileName) {
            window.open(`/api/billing/bill/pdf/${res.fileName}`, '_self')
         }

      },
      onFocus() {
         if (window.opener) {
            window.opener.postMessage({ type: 'billingPreview', data: 'update' }, window.location.origin)
         }
      },
      onContactSelect(contact) {
         this.contactSelected = contact
         if (!contact) {
            this.bill.address.email = null
            return
         }
         if (contact._id == 'download') {
            this.bill.address.email = null
         } else {
            this.bill.address.email = contact.email
         }

      },
      onDateChange(date) {
         this.billDate = date
         this.updateBillValues()
      },
      getRevenueAccounts() {
         let revenueAccounts = []
         this.bill.services
            .forEach(service => {
               if (!service.accountNo || !service.value) {
                  console.log('service', service)
               }
               if (!service.accountNo) return
               if (!service.value) return
               let accountNo = service.accountNo
               let value = service.value
               let revenueAccount = revenueAccounts.find(account => account.accountNo == accountNo)
               if (revenueAccount) {
                  revenueAccount.value += value
               } else {
                  revenueAccounts.push({ accountNo, value })
               }
            })

         return revenueAccounts
      }
   },
   computed: {
      billingContacts() {
         if (!this.customer) return []
         return this.customer.contacts.filter(contact => contact.billing && contact.email)
      },
      billingContactDropdownOptions() {
         let placeholder = 'Rechnungskontakt'
         if (this.billingContacts.length == 0) {
            placeholder = 'Rechnungskontakt fehlt'
         }
         let billingContacts = [...this.billingContacts]
         if (billingContacts.length == 0) {
            billingContacts.push({ _id: 'download', email: 'Rechnung herunterladen' })
         }
         return {
            listItems: billingContacts,
            placeholder,
            listItemKey: '_id',
            listItemValueKey: 'email',
            returnListItem: true,
         }
      },
      enableGenerateBillBtn() {
         if (this.errorMsg) return false
         if (!this.contactSelected) return false
         return true
      }
   },
   mounted() {
      window.addEventListener('message', this.onWindowMessage,)

      // post ready
      if (window.opener) {
         window.opener.postMessage({ type: 'billingPreview', data: 'update' }, window.location.origin)
      }
      window.addEventListener('focus', this.onFocus)

   },
   beforeDestroy() {
      window.removeEventListener('message', this.onWindowMessage)
      window.removeEventListener('focus', this.onFocus)
   }
}
</script>

<style scoped>
.BillingPreview {}

.BillView {
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.display {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 5px;
   padding: 5px;
   position: relative;
}

.action {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 5px;
   border-bottom: 1px solid #e0e0e0;
   width: 100%;
   column-gap: 55px;
   padding: 0 15px 15px 15px;
}

.Dropdown.billingContact {
   width: 250px;
}

.loader {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
}

.error {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   row-gap: 5px;
   padding: 8px 15px 8px 15px;
   position: relative;
   background-color: #ffdad7;
}
</style>